<template>
	<div class="bg_dark bg_intro_1">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character1">
							<div class="text_guide pt-20">
								<h4>앱 접근 권한 안내</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p>서비스 이용을 위해<br>
									아래 접근권한을 허용해 주세요</p>
							</div>
						</div>
						<div class="intro_line">
							<span class="hide">구분선</span>
						</div>
						<div class="intro_bottom">
							<h4 class="confirm_info_tit">필수 접근 권한</h4>
							<div class="confirm_info">
								<ul class="confirm_list">
									<li>
										<span class="ico_picture"><em class="hide">사진</em></span>
										<p class="confirm_info_text">
											<strong>사진/미디어/파일</strong>
											<em>이미지 캐시, 글 작성 파일 읽기 또는 저장</em>
										</p>
									</li>
									<li>
										<span class="ico_audio"><em class="hide">마이크</em></span>
										<p class="confirm_info_text">
											<strong>마이크</strong>
											<em>오디오 녹음</em>
										</p>
									</li>
									<li>
										<span class="ico_camera"><em class="hide">카메라</em></span>
										<p class="confirm_info_text">
											<strong>카메라</strong>
											<em>QR코드 찍기</em>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button class="btn_l btn_fill_gray" @click="exit">취소</button>
							<button class="btn_l btn_fill_blue" @click="setIntro">확인</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia001'
		,data: function(){
			return {
				program: {
					name: '앱 접금 권한 안내'
					, not_header: true
					, not_footer: true
				}
			}
		}
		,methods: {
			setIntro: async function(){
				await localStorage.setItem('is_intro', 'y')
				this.$emit('to', { name: 'mafia003'})
			}
			, setFile: function(e){
				console.log(e)
			}
			, exit: function(){
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			this.setIntro()
		}
	}
</script>